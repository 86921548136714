export enum HintKeys {
  DEFAULT = 'default',
  DRUG = 'drug',
  AMBIENT = 'ambient',
  BIOCIDE = 'biocide',
  CLOTHING = 'clothing',
  INVESTMENT = 'investment',
  SPECIAL_NO_RETURN = 'special-no-Return',
  DANGEROUS_GOOD = 'dangerous-good',
}

export interface Hint<DATA extends Record<string, any> = Record<string, any>> {
  key: HintKeys;
  data?: DATA;
}
